import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  ListGroup,
} from "react-bootstrap"
import WhiteLogo from "../images/logo-white.svg"
import close from "../images/close.svg"
import { FR, getUserLanguage, translate } from "../utils"

const Footer = () => {
  const [showFooter, setShowFooter] = useState(false);
  const [placeholderYourEmail,setplaceholderYourEmail] = useState('');

  useEffect(() => {
    setplaceholderYourEmail("_"+translate('enter your email'))
  },[])

  const toggleFooter = () => setShowFooter(!showFooter)

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="pre-footer feed-footer-yellow feed-footer-yellow-ahmad">
              <ListGroup variant="flush">
                <ListGroup.Item className="font-size-14">
                  <span>E :</span> info@enter-business.com
                </ListGroup.Item>
              </ListGroup>
              <div className="footer-content">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => toggleFooter()}
                >
                  _Footer
                </button>
                <p>
                  enter-business 2021
                  <span className="d-block">© All Rights Reserved</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className={`footerWrap ${showFooter ? "show" : ""}`}>
        <img
          src={close}
          alt="close footer"
          className="close-icon"
          onClick={() => toggleFooter()}
        />
        <div className="footer">
          <Container>
            <Row className="align-items-center">
              <Col sm="12" md="4" lg="3" xl="2">
                <div className="text-center text-md-left mb-4 mb-md-0">
                  <img
                    className="img-fluid"
                    src={WhiteLogo}
                    alt="Enter Business"
                  />
                </div>
              </Col>
              <Col sm="12" md="8" lg="4" xl="4">
                <ListGroup variant="flush">
                  <ListGroup.Item className="font-size-14">
                    <span>E :</span> info@enter-business.com
                    <p className="fp">enter-business is service of In Tempore sl/bv  - Avenue Kersbeeklaan  306 - 1180 Brussels - Belgium – registered nr BE 0477 292 458</p>
                  </ListGroup.Item>

                </ListGroup>
              </Col>
              {/*<Col md="12" lg="1" xl="3"></Col>
              <Col md="12" lg="4" xl="3">
                <Form>
                  <p className="mt-4 text-secondary mb-2 text-center text-md-right text-white">
                    Get in touch with us right now.
                  </p>
                  <InputGroup>
                    <FormControl
                      placeholder={placeholderYourEmail}
                      aria-label=""
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                      <InputGroup id="basic-addon2">
                        <Button
                          variant="success text-uppercase"
                          onClick={() => toggleFooter()}
                        >
                          Go
                        </Button>
                      </InputGroup>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="bottom-footer">
          <Container>
            <Row className="row row-no-gutters">
              <Col sm="12" md="12" lg="5" className="text-center text-lg-left">
                <p>enter-business 2021 © All Rights Reserved</p>
              </Col>
              <Col sm="12" md="12" lg="7">
                <ul className="list-unstyled">
                  <li>
                    <a href={(getUserLanguage()===FR)?'/site-terms-fr':'/site-terms-nl'}>{translate("gebruikersvoorwaarden")}</a>
                  </li>
                  <li>
                    <a href={(getUserLanguage()===FR)?'/privacy-terms-fr':'/privacy-terms-nl'}>{translate("privacy")}</a>
                  </li>
                  {/* <li>
                    <a href={'#'}>{translate("advertisement")}</a>
                  </li>
                  <li>
                    <a href={'#'}>{translate("cookies Policy")}</a>
                  </li> */}
                  <li>
                    <a href={'mailto:info@enter-business.com'}>{translate("contact Us")}</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Footer